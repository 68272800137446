<template>
  <CFooter :fixed="false">
    <div>
      <a href="https://hinweise.greenpeace.de" target="_blank">Klimadashboard</a>
      <span class="ml-1">&copy; {{new Date().getFullYear()}} Greenpeace e.V.</span>
    </div>
    <div class="mfs-auto ">
      <button @click="() => $store.commit('toggle', 'darkMode')" class="c-footer-nav-btn">
        <CIcon v-if="$store.state.darkMode" name="cil-sun"/>
        <CIcon v-else name="cil-moon"/>
      </button>
    </div>
  </CFooter>
</template>

<script>
export default {
  name: 'TheFooter'
}
</script>
