<template>
    <CSidebar
            fixed
            :minimize="minimize"
            :show="show"
            @update:show="(value) => $store.commit('set', ['sidebarShow', value])"
    >
        <CSidebarBrand class="d-md-down-none pt-2" v-bind:class="{ 'pb-2': minimize, 'pb-4': !minimize }"  to="/">
            <CImg v-if="!minimize" class="c-sidebar-brand-full" src="img/logo4_transparent.png" align="center" block width="90%"></CImg>
            <CImg v-else class="c-sidebar-brand-minimized" src="img/avatar_logo1_transparent.png" align="center" block width="90%"></CImg>
        </CSidebarBrand>

        <CRenderFunction flat :contentToRender="sidebarItems"/>
        <CSidebarMinimizer
                class="d-md-down-none"
                @click.native="$store.commit('set', ['sidebarMinimize', !minimize])"
        />
    </CSidebar>
</template>

<script>
import SidebarItems from './SidebarItems'
export default {
  name: 'TheSidebar',
  extends: SidebarItems,
  computed: {
    show () {
      return this.$store.state.sidebarShow
    },
    minimize () {
      return this.$store.state.sidebarMinimize
    }
  }
}
</script>

